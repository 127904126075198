import { EvlTypography } from 'evl-ui-components';
import useStyles from './LoaderJSS';

interface LoaderProps {
  message?: string;
}

const Loader: React.FC<LoaderProps> = ({ message }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.loader}></div>
      {message && <EvlTypography className={classes.loadMore}>{message}</EvlTypography>}
    </>
  );
};

export default Loader;
