import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    loader: {
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '10px',
      margin: '0px 10px',
      textIndent: '-9999em',
      width: '46px',
      height: '46px',
      borderRadius: '50%',
      background: 'linear-gradient(to right, #407279 10%, rgba(255, 255, 255, 0) 42%)',
      position: 'relative',
      animation: '$load3 1.4s infinite linear',
      transform: 'translateZ(0)',
      '&::before': {
        width: '50%',
        height: '50%',
        background: '#407279',
        borderRadius: '100% 0 0 0',
        position: 'absolute',
        top: 0,
        left: 0,
        content: '""',
      },
      '&::after': {
        background: '#FAFAFA',
        width: '75%',
        height: '75%',
        borderRadius: '50%',
        content: '""',
        margin: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      },
    },
    '@keyframes load3': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
    loadMore: {
      fontSize: '14px',
      color: '#636363',
      marginTop: '15px',
      fontStyle: 'italic',
    },
  }),
);

export default useStyles;
